<template>
    <div class="news_page">
        <Banner :adCategory="5"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb v-if="breadcrumbList" :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>

        <div class="content">
            <div class="navBar_box">
                <div class="navBar display">
                    <div class="navBar_list display pointer" v-for="(item, index) in navBarList" :key="index"
                        @click="changeNavBar(item, index)" :class="navBarIndex == item.id ? 'navBarActive' : ''">
                        {{ item.name }}
                    </div>

                </div>
            </div>
            <div v-if="type != 3">
                <NewsList :newsList="newsList" :fatherId="navBarIndex"></NewsList>
            </div>
            <div class="video_box" v-else>
                <div class="classify_box display">
                    <div class="classify_list pointer display" :class="classifyIndex == index ? 'classifyActive' : ''"
                        v-for="(item, index) in classifyList" :key="index" @click="changeBtn(item, index)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="video_mian">
                    <VideoList :videoList="videoList"></VideoList>
                </div>
            </div>
            <div class="pages">
                <pages :params="type != 3 ? params : paramsVideo" @nextPage="nextPage" :total="total"></pages>
            </div>
        </div>

    </div>
</template>
<script>

import Banner from '@/components/banner'
import Breadcrumb from '@/components/breadcrumb'
import NewsList from '@/components/newsList'
import VideoList from './components/videoList'
import Pages from '@/components/pages'
export default {
    name: "news",
    components: {
        Banner,
        NewsList,
        Breadcrumb,
        Pages,
        VideoList
    },
    data() {
        return {
            navBarIndex: 1,
            classifyIndex: 0,
            type: 1,
            breadcrumbList: [
                {
                    name: "新闻资讯",
                    url: ''
                },
            ],
            newsList: [],
            videoList: [],
            classifyList: [
                {
                    id: 1,
                    name: '企业视频'
                },
                {
                    id: 2,
                    name: '产品介绍'
                },
                {
                    id: 3,
                    name: '解决方案'
                },
                {
                    id: 4,
                    name: '工程案例'
                },
            ],
            navBarList: [
                {
                    id: 1,
                    name: '公司新闻'
                },
                {
                    id: 2,
                    name: '行业资讯'
                },
                {
                    id: 3,
                    name: '视频中心'
                }
            ],
            params: {
                page: 1,
                limit: 9,
                newsType: 1,
            },
            paramsVideo: {
                page: 1,
                limit: 6,
                videoType: 1,
            },
            total: 10
        }
    },
    watch: {
        '$store.state.typeId'() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.getData()
    },
    methods: {
        init() {
            let navBarData = JSON.parse(localStorage.getItem('navBarData'))
            this.navBarIndex = navBarData.id
            this.type = navBarData.id
            if (this.type == 3) {
                this.classifyIndex = 0
                this.paramsVideo.videoType = this.classifyList[0].id
                this.getVideoData()

            } else if (this.type != 3 && this.type != '') {
                this.params.newsType = this.type
                this.getData()

            }
            let navName = ''
            if (this.type == 1) {
                navName = '公司新闻'
            } else if (this.type == 2) {
                navName = '行业资讯'
            } else {
                navName = '视频中心'
            }
            this.breadcrumbList = [
                {
                    name: "新闻资讯",
                    url: ''
                },
                {
                    name: navName,
                    url: ''
                },
            ]
        },
        getData() {
            this.api.findPageNews(this.params).then(res => {
                console.log(res);
                res.data.forEach(item => {
                    item.createTime = item.createTime.slice(0, 10)
                })
                this.newsList = res.data
                this.total = res.count
            })
        },
        getVideoData() {
            this.api.findPageVideo(this.paramsVideo).then(res => {
                this.videoList = res.data
                this.total = res.count
            })
        },
        changeNavBar(item) {
            this.navBarIndex = item.id
            this.type = item.id
            this.params.page = 1
            this.paramsVideo.page = 1
            this.classifyIndex = 0
            document.title = item.name + '—果尔佳'

            this.breadcrumbList = [
                {
                    name: "新闻资讯",
                    url: ''
                },
                {
                    name: item.name,
                    url: ''
                },
            ]
            if (item.id == 3) {
                this.getVideoData()
            } else {
                this.params.newsType = item.id
                this.getData()
            }
        },
        changeBtn(item, index) {
            this.paramsVideo.videoType = item.id
            this.paramsVideo.page = 1
            this.classifyIndex = index
            this.getVideoData()
        },
        nextPage(page) {
            if (this.type != 3) {
                this.params.page = page
                this.getData()
            } else {
                this.paramsVideo.page = page
                this.getVideoData()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.news_page {
    .breadcrumb_box {
        background: #f4f5f5;
    }

    .navBar_box {
        margin-top: 51px;
    }
}

.video_box {
    .classify_box {
        margin-top: 34px;

        .classify_list {
            width: 126px;
            height: 20px;
            font-size: 16px;
            color: #333333;
            border-right: 1px solid #DEDEDE;
        }

        .classify_list:last-child {
            border-right: none !important;
        }

        .classifyActive {
            color: #E62129 !important;
        }
    }
}

.video_mian {
    margin-top: 30px;
}

.pages {
    display: flex;
    justify-content: flex-end;
}

.navBar {
    margin-top: 29px;

    .navBar_list {
        width: 124px;
        height: 46px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 0px 10px;
    }

    .navBarActive {
        background: #E62129 !important;
        border: 1px solid #E62129 !important;
        color: #fff !important;
    }
}
</style>